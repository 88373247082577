// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-cabins-tsx": () => import("./../../../src/templates/cabins.tsx" /* webpackChunkName: "component---src-templates-cabins-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-retreats-tsx": () => import("./../../../src/templates/retreats.tsx" /* webpackChunkName: "component---src-templates-retreats-tsx" */)
}

